@import url("./font.css");
.App {
  /* text-align: center; */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.icon_female{
  margin-top: -20px;
}
.lighting{
  position: absolute;
width: 45px;
left: 70%;
top: 73%;
}

@media screen and (max-width: 1440px) {
  .lighting{
    position: absolute;
  width: 45px;
  left: 70%;
  top: 69%;
  }
}
@media screen and (max-width: 1240px) {
  .lighting{
    position: absolute;
  width: 45px;
  left: 70%;
  top: 67%;
  }
}
@media screen and (max-width: 1171px) {
  .lighting{
    position: absolute;
  width: 45px;
  left: 70%;
  top: 82%;
  }
}
@media screen and (max-width: 800px) {
  .lighting{
    position: absolute;
  width: 45px;
  left: 70%;
  top: 64%;
  }
}
@media screen and (max-width: 680px) {
  .lighting{
    position: absolute;
  width: 45px;
  left: 80%;
  top: 70%;
  }
}
.male{
  position: absolute;
width: 6%;
right: 29%;
top: 19%;
}
@media screen and (max-width: 1440px) {
  .male{
    position: absolute;
  right: 17%;
    top:19%;
  width: 6%;
  }
}
@media screen and (max-width: 1240px) {
  .male{
    position: absolute;
  right: 12%;
    top:23%;
  width: 8%;
  }
}
@media screen and (max-width: 700px) {
  .male{
    position: absolute;
width: 10%;

right: 15%;
top: 22%;
}
}
.hope{
  position: absolute;
top: 20%;
left: 50%;

}
@media screen and (max-width: 1240px) {
  .hope{
    position: absolute;
  top: 26%;
  left: 35%;
  
  }
}
.female{
  position: absolute;
left: 18%;
top:16%;
width: 12%;
}
@media screen and (max-width: 1240px) {
  .female{
    position: absolute;
  left: 6%;
  top:16%;
  width: 16%;
  }
}
@media screen and (max-width: 700px) {
  .female{
    position: absolute;
  left: 6%;
  top: 17%;
  width: 18%;
  }
}
.section-width{
  width: 83%;
  margin: auto;
}
.section-width2{
  width: 60%;
  margin: auto;
}
@media screen and (max-width: 800px) {
  .section-width2{
    width: 83%;
    margin: auto;
  }
}
.section2-detail1{
  font-family:Sequel Sans !important;
font-style: normal;
font-weight: normal;
width: 84%;
font-size: 32px !important;
line-height: 40px !important;
/* or 125% */

display: flex;
align-items: center;
letter-spacing: 0.015em !important;

/* Text/Dark */

color: #1E1E2F;
}
@media screen and (max-width: 900px) {
  .section2-detail1{
    width: 100%;
    font-size: 32px !important;
  }
}
@media screen and (max-width: 900px) {
  .section2-detail2{
    width: 100%;
    
  }
}
.section2-detail2{
  
font-family: Graphik !important;
font-style: normal !important;
font-weight: normal !important;
font-size: 20px !important ;
line-height: 28px !important;
/* or 140% */
width: 93%;

display: flex;
align-items: center;

/* Text/Body */

color: #5F5F8C;
}
.section3-detail2{
  
  font-family: Graphik !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 16px !important ;
  line-height: 28px !important;
  /* or 140% */
  width: 90%;
  
  display: flex;
  align-items: center;
  
  /* Text/Body */
  
  color: #5F5F8C;
  }
@media screen and (max-width: 900px) {
  .section2-detail2{
    width: 100%;
    
  }
}
.section2-main{
  padding-top: 80px;
  padding-bottom: 135px;
}
.section3-main{
  padding: 80px;
  border-radius: 24px 24px 0px 0px;
  margin-top: 160px;
}
@media screen and (max-width: 900px) {
  .section2-main{
    padding-top: 40px;
  padding-bottom: 100px;
    
  }
}
@media screen and (max-width: 1200px) {
  .section3-main{
    padding: 40px;
    
  }
}
.section2-detail3{
  font-family:Sequel Sans !important;
font-style: normal;
font-weight: normal;
font-size: 32px !important;
line-height: 40px !important;
/* identical to box height, or 125% */

display: flex;
align-items: center;
letter-spacing: 0.015em !important;

/* Text/Dark */

color: #1E1E2F;
}

.section2-detail4{
  font-family: Graphik !important;
font-style: normal !important;
font-weight: normal !important;
font-size: 20px !important;
line-height: 28px !important;
/* identical to box height, or 140% */

display: flex;
align-items: center;

/* Text/Body */

color: #5F5F8C;
}
.image2-details2{
  width: 70%;
  float: right;
  display: flex;
}
.image2-details{
  width: 80%;

}
@media screen and (max-width: 900px) {
  .image2-details{
    width: 100%;
    
  }
}
@media screen and (max-width: 1200px) {
  .image2-details2{
    width: 100%;
    float: left;
  }
}
@media screen and (max-width: 900px) {
  .image2-details2{
    width: 100%;
    float: left;
  }
}
.section-button2{
  display: flex;
flex-direction: column;
align-items: center;
text-transform: none !important;
padding: 16px 24px !important;


/* White */

background: #FFFFFF;
/* Border/Dark */
font-family: Graphik !important;
font-style: normal !important;
font-weight: 500 !important;
font-size: 14px !important;
line-height: 170% !important;


/* Text/Dark */

color: #1E1E2F !important;
border: 1px solid #CECEDE !important;
box-sizing: border-box;
border-radius: 10px !important;
}
.parent-head{
  background: #28284D;
mix-blend-mode: normal;
padding-top: 166px;
margin-top: 55px;
padding-bottom: 128px;
}
.parent-head2{
  background: #1E1E2F;
mix-blend-mode: normal;
border-radius: 0px 0px 24px 24px;
padding-top: 80px;
padding-bottom: 80px;
}
.main-head{
  width: 23%;
  margin: auto;
  justify-content: center;
  display: flex;
  flex-direction: column;

}
.main-head2{
  width: 33%;
  margin: auto;
  justify-content: center;
  display: flex;
  flex-direction: column;
}
@media screen and (max-width: 1240px) {
  .main-head{
    width: 50%;
   
  }
}
@media screen and (max-width: 1440px) {
  .main-head2{
    width: 43%;
   
  }
}
@media screen and (max-width: 1240px) {
  .main-head2{
    width: 90%;
   
  }
}
@media screen and (max-width: 2000px) {
  .main-head{
    width: 36%;
   
  }
}
@media screen and (max-width: 1240px) {
  .main-head{
    width: 56%;
   
  }
}
@media screen and (max-width: 800px) {
  .main-head{
    width: 85%;
   
  }
}

.first-heading{
  
  font-family:Sequel Sans !important;

  justify-content: center;

  font-size: 58px !important;
  line-height: 70px !important;
  /* or 121% */
  padding-bottom: 16px;

  align-items: center;
  text-align: center;
  letter-spacing: 0.015em;
  
  /* White */
  
  color: #FFFFFF;
}
@media screen and (max-width: 800px) {
  .first-heading{
    font-size: 34px !important;
    line-height: 40px !important;
  }
}
.first-heading2{
  font-family:Sequel Sans !important;
font-style: normal;
font-weight: normal;
font-size: 32px !important;
line-height: 40px !important;
/* or 125% */
padding-bottom: 16px;
width: 90%;
display: flex;
justify-content: center;
align-items: center;
text-align: center;
letter-spacing: 0.015em !important;

/* White */

color: #FFFFFF;
}
@media screen and (max-width: 1240px) {
  .first-heading2{
    width: 100%;
   
  }
}
.detail-head{
  font-family: Graphik !important;
font-style: normal !important;
font-weight: normal !important;
font-size: 18px !important;
padding-bottom: 24px;
line-height: 26px !important;
/* or 144% */

display: flex;
align-items: center;
text-align: center;

/* Purple/200 */

color: #E4E5FB;
}
.detail-head2{
  font-family: Graphik !important;
font-style: normal !important;
font-weight: normal !important;
font-size: 16px !important;
line-height: 28px !important;

padding-bottom: 24px;
/* or 140% */

display: flex;
align-items: center;
text-align: center;

/* Purple/100 */

color: #F6F6FE;
}
.section3-detail{
  font-family: Graphik !important;
font-style: normal !important;
font-weight: bold !important;
font-size: 16px !important;
line-height: 150% !important;
/* identical to box height, or 24px */


/* Text/Dark */

color: #1E1E2F;
}
.section3-detail4{
  font-family: Graphik !important;
font-style: normal !important;
font-weight: normal !important;
font-size: 16px !important;
line-height: 160% !important;
/* or 26px */


/* Text/Medium */

color: #5E5E73;
width: 65% !important;
}
.button-head{
  font-family: Graphik !important;
font-style: normal !important;
font-weight: 500 !important;
font-size: 16px !important;
text-transform: none !important;
line-height: 150% !important;
/* identical to box height, or 24px */

display: flex;
align-items: center;
text-align: center;

padding: 16px 24px !important;



/* Purple/300 */

background-color: #585ADF !important;
/* Elevation/3 */

box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.05) !important;
border-radius: 10px !important;


/* White */

color: #FFFFFF;
}
.App-header {
  background-color: #18182F !important;
  /* min-height: 100vh; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.footer{
  
/* Body */

font-family: Graphik !important;
font-style: normal !important;
font-weight: normal !important;
font-size: 16px !important;
line-height: 160% !important;
padding: 20px 0px;
justify-content: center;
/* identical to box height, or 26px */

display: flex;
align-items: center;

/* Text/Medium */

color: #5E5E73;

}
.faq_title{
  font-family: Graphik !important;
font-style: normal !important;
font-weight: 900 !important;
font-size: 17px !important;
line-height: 150% !important;
/* identical to box height, or 24px */

display: flex;
align-items: center;

/* Text/Dark */

color: #1E1E2F !important;
}
.text{
  font-family: Graphik !important;
font-style: normal !important;
font-weight: normal !important;
font-size: 16px !important;
line-height: 160% !important;
margin-right: 40px !important;
/* or 26px */

margin: 0;

/* Text/Medium */

color: #5E5E73 !important;
}
@media screen and (max-width: 800px) {
  .text{
    margin-right: 0px !important;
  }
}
.faq_main{
  background: #F6F6F8;
border-radius: 12px;
padding: 23px;
cursor: pointer;
margin-bottom: 16px;
}
.read-or-hide{
  color: #4d4d7f !important;
  font-weight: 600 !important;
}
.css-11b3ww9-MuiPaper-root-MuiAppBar-root{
  background-color: #18182F !important;
}
.css-5poeop {
   background-color: #18182F !important;
}
.css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper{
  top:64px !important;
}
.css-1e6y48t-MuiButtonBase-root-MuiButton-root {
  text-transform: none !important;
}
.css-1ujsas3{
  text-transform:none !important;
}
